<template>
  <div class="mt-3">
    <v-row class="mt-5 pt-5">
      <v-col cols="auto" align-self="center">
        <v-btn icon :to="`/wettkampf/${veranstaltung.id}/anmeldungen`" exact>
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" class="text-center" align-self="center">
        <v-icon x-large>mdi-file</v-icon>
      </v-col>
      <v-col align-self="center">
        <h3 class="white--text font-weight-bold">
          ANMELDUNGEN IMPORTIEREN
        </h3>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-card
          :color="template.colors.blocks"
          class="rounded-xl pa-0"
          :style="`color: ${template.colors.block_text};`"
        >
          <v-row justify="center">
            <v-col cols="11" class="pb-0 pt-5" v-if="!csvfile.length > 0">
              <h3>Info:</h3>
              <p>
                <b>Baue deine CSV-Datei wie folgt auf:</b>
                <br />
                Bitte verwende nur CSV-Dateien im Format UTF-8
              </p>
              <code v-if="template.var.verband == 'DEU'">
                ID;Name;Vorname;Strasse;PLZ;Ort;Nation;Telefon;E-Mail;Funktion;Gruppe
                ID1;Name1;Vorname1;Strasse1;PLZ1;Ort1;Nation1;Telefon1;E-Mail1;Funktion1;Gruppe1
                ID2;Name2;Vorname2;Strasse2;PLZ2;Ort2;Nation2;Telefon2;E-Mail2;Funktion2;Gruppe2
              </code>
              <code
                v-if="
                  template.var.verband != 'DEU' &&
                  !veranstaltung.data.nation_required
                "
              >
                Startnummer;Name;Vorname;Strasse;PLZ;Ort;Telefon;E-Mail;Funktion;Disziplin;Geburtsdatum;Geimpft;Genesen
              </code>
              <code
                v-if="
                  template.var.verband != 'DEU' &&
                  veranstaltung.data.nation_required
                "
              >
                Startnummer;Name;Vorname;Strasse;PLZ;Ort;Nation;Telefon;E-Mail;Funktion;Disziplin;Geburtsdatum;Geimpft;Genesen
              </code>

              <v-divider class="my-2"></v-divider>
            </v-col>
            <v-col cols="11" class="pb-0 pt-5">
              <vue-csv-import
                v-model="csvfile"
                :map-fields="
                  template.var.verband == 'DEU'
                    ? [
                        'ID',
                        'Name',
                        'Vorname',
                        'Strasse',
                        'PLZ',
                        'Ort',
                        'Nation',
                        'Telefon',
                        'Email',
                        'Funktion',
                        'Gruppe',
                        'Geburtsdatum',
                        'Geimpft',
                        'Genesen',
                      ]
                    : this.veranstaltung.data.nation_required
                    ? [
                        'Startnummer',
                        'Name',
                        'Vorname',
                        'Strasse',
                        'PLZ',
                        'Ort',
                        'Telefon',
                        'Email',
                        'Funktion',
                        'Disziplin',
                        'Geburtsdatum',
                        'Geimpft',
                        'Genesen',
                      ]
                    : [
                        'Startnummer',
                        'Name',
                        'Vorname',
                        'Strasse',
                        'PLZ',
                        'Ort',
                        'Nation',
                        'Telefon',
                        'Email',
                        'Funktion',
                        'Disziplin',
                        'Geburtsdatum',
                        'Geimpft',
                        'Genesen',
                      ]
                "
                tableClass="table border"
                :headers="true"
                :autoMatchFields="true"
                v-if="!csvfile.length > 0"
              >
                <template slot="error">
                  Dateityp ist ungültig
                </template>

                <template slot="thead">
                  <p>
                    Weise die Spalten aus deiner Datei den benötigten Feldern zu
                  </p>
                  <tr>
                    <th style="border-bottom: 1px solid #000;">
                      Benötigte Felder
                    </th>
                    <th style="border-bottom: 1px solid #000;">
                      Spalten in deiner Datei
                    </th>
                  </tr>
                </template>

                <template slot="next" slot-scope="{ load }">
                  <v-btn
                    large
                    block
                    outlined
                    class="my-3"
                    :color="template.colors.primary"
                    @click.prevent="load"
                  >
                    DATEI LADEN
                  </v-btn>
                </template>

                <template slot="submit" slot-scope="{ submit }">
                  <v-btn
                    large
                    :color="template.colors.primary"
                    @click.prevent="submit"
                  >
                    IMPORT STARTEN
                  </v-btn>
                </template>
              </vue-csv-import>
            </v-col>
            <v-col cols="11" class="pb-0 pt-5" v-if="csvfile.length > 0">
              <v-row>
                <v-col cols="12">
                  <h2>Import-Vorschau</h2>
                </v-col>
                <v-col cols="12">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            {{
                              template.var.verband == 'DEU'
                                ? 'ID'
                                : 'Startnummer'
                            }}
                          </th>
                          <th class="text-left">
                            Name
                          </th>
                          <th class="text-left">
                            Vorname
                          </th>
                          <th class="text-left">
                            Adresse
                          </th>
                          <th
                            class="text-left"
                            v-if="
                              template.var.verband == 'DEU' ||
                              veranstaltung.data.nation_required
                            "
                          >
                            Nation
                          </th>
                          <th class="text-left">
                            Telefon
                          </th>
                          <th class="text-left">
                            E-Mail
                          </th>
                          <th class="text-left">
                            Funktion
                          </th>
                          <th class="text-left">
                            {{
                              template.var.verband == 'DEU'
                                ? 'Gruppe'
                                : 'Disziplin'
                            }}
                          </th>
                          <th class="text-left">
                            Geburtsdatum
                          </th>
                          <th class="text-left">
                            Geimpft
                          </th>
                          <th class="text-left">
                            Genesen
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, index) in csvfile" :key="index">
                          <td v-if="template.var.verband == 'DEU'">
                            {{ row.ID }}
                          </td>
                          <td v-if="!template.var.verband == 'DEU'">
                            {{ row.Startnummer }}
                          </td>
                          <td>{{ row.Name }}</td>
                          <td>{{ row.Vorname }}</td>
                          <td>
                            {{ row.Strasse }}, {{ row.PLZ }} {{ row.Ort }}
                          </td>
                          <td v-if="veranstaltung.data.nation_required">
                            {{ row.Nation }}
                          </td>
                          <td>{{ row.Telefon }}</td>
                          <td>{{ row.Email }}</td>
                          <td>{{ row.Funktion }}</td>
                          <td v-if="template.var.verband == 'DEU'">
                            {{ parse_disziplin(row.Gruppe) }}
                          </td>
                          <td v-if="!template.var.verband == 'DEU'">
                            {{ parse_disziplin(row.Disziplin) }}
                          </td>
                          <td>{{ row.Geburtsdatum || '' }}</td>
                          <td>{{ row.Geimpft ? 'Ja' : '' }}</td>
                          <td>{{ row.Genesen ? 'Ja' : '' }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="text-center pb-0">
              <v-card
                class="rounded-b-xl pa-4"
                :color="template.colors.primary"
                :disabled="loading || csvfile.length == 0"
                @click="startImport()"
              >
                <span class="font-weight-bold" v-if="!loading">
                  <v-icon class="mr-2">mdi-upload</v-icon>
                  ANMELDUNGEN IMPORTIEREN
                </span>
                <v-progress-circular
                  indeterminate
                  :color="template.colors.inline_primary_text"
                  :style="`color: ${template.colors.primary};`"
                  v-if="loading"
                ></v-progress-circular>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
//import firebase from 'firebase'
import store from '../../../../../store'
import router from '../../../../../routes/index'
import { VueCsvImport } from 'vue-csv-import'

export default {
  name: 'Anwesenheitsliste',
  data() {
    return {
      csvfile: [],
      loading: false,
      disziplinen: [],
    }
  },
  components: { VueCsvImport },
  computed: {
    ...mapGetters({
      user: 'user',
      veranstaltung: 'veranstaltung',
      template: 'template',
    }),
  },
  mounted() {
    firebase
      .firestore()
      .collection('User')
      .doc(this.user.data.uid)
      .collection('Veranstaltung')
      .doc(this.veranstaltung.id)
      .collection('Disziplin')
      .onSnapshot((snap) => {
        this.disziplinen = []
        var i = 0
        snap.forEach((doc) => {
          this.disziplinen.push(doc.data())
          this.disziplinen[i].id = doc.id
          i++
        })
      })
  },
  methods: {
    parse_disziplin(data) {
      var daten = this.get_disziplinen(data)
      var text = ''
      for (let i = 0; i < daten.objects.length; i++) {
        if (i != 0) {
          text += '\n'
        }
        text += daten.objects[i].name
        if (daten.objects[i].altersklasse) {
          text += ' (' + daten.objects[i].altersklasse + ')'
        }
      }
      return text
    },
    get_disziplinen(data) {
      var output = {
        objects: [],
        ids: [],
      }
      data = data.split(',')
      for (let i = 0; i < this.disziplinen.length; i++) {
        for (let j = 0; j < data.length; j++) {
          if (this.disziplinen[i].importname == data[j]) {
            output.objects.push(this.disziplinen[i])
            output.ids.push(this.disziplinen[i].id)
          }
        }
      }
      return output
    },
    async startImport() {
      if (this.csvfile) {
        this.loading = true
        var i
        for (i = 0; i < this.csvfile.length; i++) {
          var disziplinen = this.get_disziplinen(this.csvfile[i].Disziplin)
          var daten = {
            person: {
              vorname: this.csvfile[i].Vorname,
              name: this.csvfile[i].Name,
              adresse: this.csvfile[i].Strasse,
              ort: this.csvfile[i].Ort,
              plz: this.csvfile[i].PLZ,
              nation: this.csvfile[i].Nation ? this.csvfile[i].Nation : '',
              telefon: this.csvfile[i].Telefon,
              email: this.csvfile[i].Email,
              aktivitaet: this.csvfile[i].Funktion,
            },
            geimpft: this.csvfile[i].Geimpft ? true : false,
            genesen: this.csvfile[i].Genesen ? true : false,
            geburtsdatum: this.csvfile[i].Geburtsdatum || '',
            disziplinen: disziplinen.objects,
            disziplinen_ids: disziplinen.ids,
            startnummer: this.csvfile[i].Startnummer,
          }
          await firebase
            .firestore()
            .collection('User')
            .doc(this.user.data.uid)
            .collection('Veranstaltung')
            .doc(this.veranstaltung.id)
            .collection('Anmeldung')
            .add(daten)
            .catch((error) => {
              console.log(error)
            })
        }
        this.loading = false
        router.push('/wettkampf/' + this.veranstaltung.id + '/anmeldungen')
      }
    },
  },
}
</script>

<style scoped>
.border {
  border-bottom: 1px solid #000;
}
</style>
